/* div img:hover {
    border: 1px solid blue;
    border-radius: 5px;
    padding: 5px;
} */

.home-p {
    border: 1px solid blue;
    color: blue;
    border-radius: 5px;
    padding: 0px 2px 2px 2px;   
    text-align: center; 
    margin: 2px;
}

.home-p:hover {
    border: 5px solid blue;
    border-radius: 10px;
    font-weight: bold;
    color: red;
}