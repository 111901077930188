.chapter-container {
    margin: 4px 5px 0px 3px;
    border: 2px solid dodgerblue;
    border-radius: 5px;
}

.chpter-header {
    text-align: center;
    color: dodgerblue;
    font-weight: bold;
}

.paragrph {
    text-align: center;
    color: dodgerblue;
    
}

.chapter-p {
    font-size: 14pt;
}

.span-1 {
    border: 1px solid red;
    color: red;
    padding: 0px 2px 0px 2px;
    border-radius: 4px;    
    
}
.span-1:hover {
    color: white;
    background-color: red;
}

.span-2 {
    border: 1px solid purple;
    color: purple;
    padding: 0px 2px 0px 2px;
    border-radius: 4px;
    
    
}
.span-2:hover {
    color: white;
    background-color: purple;
}

.span-3 {
    border: 1px solid dodgerblue;
    color: dodgerblue;
    padding: 0px 2px 0px 2px;
    border-radius: 4px;
    
    
}
.span-3:hover {
    color: white;
    background-color: dodgerblue;
}

.span-4 {
    border: 1px solid green;
    color: green;
    padding: 0px 2px 0px 2px;
    border-radius: 4px;
    
    
}
.span-4:hover {
    color: white;
    background-color: green;
}

.span-5 {
    border: 1px solid darkblue;
    color: darkblue;
    padding: 0px 2px 0px 2px;
    border-radius: 4px;
    
    
}
.span-5:hover {
    color: white;
    background-color: darkblue;
}

.span-6 {
    border: 1px solid black;
    color: black;
    padding: 0px 2px 0px 2px;
    border-radius: 4px;
    
    
}
.span-6:hover {
    color: white;
    background-color: black;
}

.img-figure {
    width: 60%;
    height: 60%;
}

