.info-body tr td:nth-of-type(1) {
    background-image: linear-gradient(to right,#009FFF, #021B79, #0575E6);
    color: #ffffff;
    border-radius: 10px;    
}
.info-body tr td:nth-of-type(2) {
    background-image: linear-gradient(to right,#0575E6, #021B79, #009FFF);
    color: #ffffff;
    border-radius: 10px;    
}


.info-header tr th:nth-of-type(1) {
    background-image: linear-gradient(to right, #ec38bc, #7303c0, #03001e);
    color: #ffffff;
    border-radius: 10px;
    
}

.info-header tr th:nth-of-type(2) {
    background-image: linear-gradient(to right,#03001e, #7303c0, #ec38bc);
    color: #ffffff;
    border-radius: 10px;
    
}