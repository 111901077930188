.frame {
    border: 1px solid black;
    padding-left: 5px;
    padding-right: 0px;
    padding-bottom: 5px;
    margin-left: 3px;
    width: 99%;
    text-align: center;
}

.frame p {
    color: blue;
    font-size: 12px;
    margin: 0;
    padding-bottom: 3px;
}

