.dual-pane {
    margin: 0px 3px 0px 0px;
    padding: 0px 6px 0px 0px;
}

.footer-div {
    background-image: linear-gradient(to right,blue,rgb(45, 20, 66),rgb(45, 20, 66),rgb(45, 20, 66),rgb(45, 20, 66),rgb(45, 20, 66),rgb(45, 20, 66),rgb(45, 20, 66),blue);
    border: blue solid 2px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 3px;
    margin-right: 0px;
    padding-left: 0px;
}

.footer-p {
    color: whitesmoke;
    margin-bottom: 5px;
}
